import { ethers } from 'ethers';
import * as React from 'react'
import { Dropzone } from '../components/Dropzone'
import { 
  Box, 
  Center, 
  Container, 
  Spinner, 
  useToast,
  Text,
  HStack,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Stack,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Flex,
  StackDivider,
  Image,
} from '@chakra-ui/react';
import { useWeb3React } from '@web3-react/core'
import axios from 'axios';
import { BsChevronBarDown } from 'react-icons/bs';
import { InfoPopover } from '../components/InfoPopover';
import CustomField from '../components/CustomField';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useStep } from '../components/progress/UseStep';
import { Step } from '../components/progress/Step';
import CustomAttribute from '../components/CustomAttribute';
import { arrayify } from 'ethers/lib/utils';
import { uploadMediaToS3 } from '../utils/metadata'
import { MediaGrid } from '../components/metadata/MediaGrid';
import { MediaCard } from '../components/metadata/MediaCard';

function Metadata() {
  const toast = useToast();
  const { library, active, chainId, account } = useWeb3React()

  // Constants
  const steps = [
    {
      title: 'Metadata',
      description: 'Name and Description',
    },
    {
      title: 'Custom fields',
      description: 'Common to every NFT',
    },
    {
      title: 'Documents',
      description: 'Common documents',
    },
    {
      title: 'Attributes',
      description: 'Unique for every NFT',
    },
  ]

  const initialCustomFields = []

  const initialAttributes = []

  // states
  const [isLoading, setIsLoading] = React.useState(true);
  const [metadatas, setMetadatas] = React.useState([])
  const [activeMetadata, setActiveMetadata] = React.useState(null)
  const [showCreationPage, setShowCreationPage] = React.useState(false)
  const [collectionName, setCollectionName] = React.useState("")
  const [collectionDescription, setCollectionDescription] = React.useState("")
  const [customFields, setCustomFields] = React.useState(initialCustomFields)
  const [nextCustomFieldId, setNextCustomFieldId] = React.useState(0);
  const [customAttributes, setCustomAttributes] = React.useState(initialAttributes)
  const [nextAttributeId, setNextAttributeId] = React.useState(0);
  const [mediaFiles, setMediaFiles] = React.useState([])
  const [currentMedia, setCurrentMedia] = React.useState([])
  const [activeMetadataId, setActiveMetadataId] = React.useState(0)
  const [attachedFiles, setAttachedFiles] = React.useState([])

  // States
  const [currentStep, { setStep }] = useStep({
    maxStep: steps.length,
    initialStep: 0,
  })
  
  // effetc
  React.useEffect(() => {
    if (active) {
      handleUpdateMetadata(true, 0).then();
    }

  }, [active, account, chainId])

  // functions
  const handleUpdateMetadata = async (changeActiveMetadata, index) => {
    setIsLoading(true);

    if (changeActiveMetadata === undefined) {
      changeActiveMetadata = true
    }   

    const response = await axios.get(process.env.REACT_APP_API_URL + '/metadata/?owner=' + account)
    if (response.status !== 200) {
      setActiveMetadata(null);
      toast.closeAll();
      toast({
        title: "You don't have any metadata",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false)
      return
    }

    const newMetadatas = response.data.metadatas 
    let newActiveMetadata = newMetadatas[activeMetadataId]
    setMetadatas(newMetadatas)
    if (newMetadatas.length > 0) {
      if (changeActiveMetadata && index !== undefined) {
        setActiveMetadata(newMetadatas[index])
        newActiveMetadata = newMetadatas[index]
        setActiveMetadataId(index)
      } else {
        for (let i = 0; i < newMetadatas.length; i++) {
          if (newMetadatas[i].id === activeMetadata.id) {
            setActiveMetadata(newMetadatas[i])
            newActiveMetadata = newMetadatas[i]
            setActiveMetadataId(i)
            break;
          }
        }
      }
    } else {
      setActiveMetadata(null);
      toast.closeAll();
      toast({
        title: "You don't have any metadata",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false)
      return
    }

    const newCurrentMedia = []
    for (let i = 0; i < newActiveMetadata.nextMediaId; i++) {
      const url = "https://bi-nft-minter-d3m.s3.eu-south-1.amazonaws.com/" + newActiveMetadata.mediaUrls[i]
      newCurrentMedia.push(url)
    }

    setCurrentMedia(newCurrentMedia)

    console.log("metadata:", newActiveMetadata)

    setIsLoading(false)
  }

  const handleUpdateCustomFieldField = (id, field) => {
    // Each custom field is composed by a field, a value and an id
    let newAttributes = [...customFields];

    for (let i = 0; i < customFields.length; i++) {
      if (newAttributes[i].id === id) {
        newAttributes[i].field = field;
        break;
      }
    }

    setCustomFields(newAttributes);
  }

  const handleUpdateCustomFieldValue = (id, value) => {
    // Each custom field is composed by a field, a value and an id
    let newAttributes = [...customFields];

    for (let i = 0; i < customFields.length; i++) {
      if (newAttributes[i].id === id) {
        newAttributes[i].value = value;
        break;
      }
    }

    setCustomFields(newAttributes);
  }

  const handleDeleteCustomField = (id) => {
    let newAttributes = [...customFields];
    
    for (let i = 0; i < customFields.length; i++) {
        if (newAttributes[i].id === id) {
            newAttributes.splice(i, 1);
            break;
        }
    }

    setCustomFields(newAttributes);
  }

  const handleAddCustomField = () => {
    const obj = {
      field: "",
      value: "",
      id: nextCustomFieldId
    };
  
    setCustomFields(customFields => [...customFields, obj]);
    setNextCustomFieldId(nextCustomFieldId + 1);
  }

  const handleUpdateAttribute = (id, name) => {
    // Each custom field is composed by a name and an id
    let newAttributes = [...customAttributes];

    for (let i = 0; i < customAttributes.length; i++) {
      if (newAttributes[i].id === id) {
        newAttributes[i].name = name;
        break;
      }
    }

    setCustomAttributes(newAttributes);
  }

  const handleDeleteAttribute = (id) => {
    let newAttributes = [...customAttributes];
    
    for (let i = 0; i < customAttributes.length; i++) {
        if (newAttributes[i].id === id) {
            newAttributes.splice(i, 1);
            break;
        }
    }

    setCustomAttributes(newAttributes);
  }

  const handleAddAttribute = () => {
    const obj = {
      name: "",
      id: nextAttributeId
    };
  
    setCustomAttributes(customAttributes => [...customAttributes, obj]);
    setNextAttributeId(nextAttributeId + 1);
  }

  const handleResetCreation = () => {
    setShowCreationPage(false);
    setStep(0)
    setCustomAttributes(initialAttributes)
    setCustomFields(initialCustomFields)
    setCollectionDescription("")
    setCollectionName("")
  }

  const handleCreateMetadata = async () => {
    if (collectionName === "" || collectionDescription === "") {
      toast({
        title: "Insert at least the name and description of the metadata.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      return
    }

    // generate custom fields and attributes with backend format
    const customFieldsToSend = []
    customFields.forEach(field => {
      const toSend = {
        field: field.field,
        value: field.value
      }

      customFieldsToSend.push(toSend)
    })

    const attributesToSend = []
    customAttributes.forEach(attribute => {
      attributesToSend.push(attribute.name)
    })

    // sign message
    let signature = ""
    let message = account + JSON.stringify(customFieldsToSend) + JSON.stringify(attributesToSend)
    message = ethers.utils.id(message)
    try {
      const signer = await library.getSigner()
      signature = await signer.signMessage(arrayify(message))
    } catch (error) {
      console.log("Unable to sign message:", error.message);

      toast({
        title: "Signature rejected",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return
    }

    // Update backend
    const requestData = new FormData()
    requestData.append("owner", account)
    requestData.append("customFields", JSON.stringify(customFieldsToSend))
    requestData.append("attributes", JSON.stringify(attributesToSend))
    requestData.append("contentName", collectionName)
    requestData.append("contentDescription", collectionDescription)
    requestData.append("signature", signature)
    requestData.append("numberOfFiles", attachedFiles.length)

    for (let i = 0; i < attachedFiles.length; i++) {
      requestData.append("file_" + i.toString(), attachedFiles[i])
    } 

    axios.post(process.env.REACT_APP_API_URL + '/metadata', requestData)
    .then(response => {
      toast({
        title: "Metadata created successfully",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

      handleResetCreation();
      handleUpdateMetadata(true, 0).then()
    })
    .catch(error => {
      toast({
        title: "Unable to create metadata",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    })
  }

  const handleFilesDrop = (files) => {
    setMediaFiles(files);
  }

  const handleDocumentsDrop = (files) => {
    setAttachedFiles(files);
  }

  const handleMediaUpload = async () => {
    if (mediaFiles.length === 0) {
      toast({
        title: "Select media files to upload",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }

    // Add checks
    // ..

    // Create signature
    let signature = ""
    let message = account + activeMetadata.id
    message = ethers.utils.id(message)
    try {
      const signer = await library.getSigner()
      signature = await signer.signMessage(arrayify(message))
    } catch (error) {
      console.log("Unable to sign message:", error.message);

      toast({
        title: "Signature rejected",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return
    }
    
    // Upload

    for (let i = 0; i < mediaFiles.length; i++) {
      if(await uploadMediaToS3(mediaFiles[i], activeMetadata.id, account, signature)) {
        toast({
          title: "Media successfully uploaded",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Unable to upload media number " + i.toString(),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    }

    handleUpdateMetadata(false, 0).then()
  }

  const handleChangeActiveMetadata = async (index) => {
    await handleUpdateMetadata(true, index); 
  }

  return (
    <>
    <Container py={{ base: '4', md: '8' }}>
    {
      // On load
      isLoading && (
        <Box w="100%" h="55vh">
          <Center h="100%">
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          </Center>
        </Box>
      )
    }

    {
      // wallet not connected
      ! isLoading && ! active && (
        <Box w="100%" h="60vh" />
      )
    }

    {
      // Connected and has at least one metadata
      ! isLoading && active && ! showCreationPage && (
        <HStack w="100%">
            <Menu colorScheme='blue'>
              <MenuButton as={Button} rightIcon={<BsChevronBarDown />}>
                { 
                  activeMetadata ? activeMetadata.id + " - " + activeMetadata.contentName : "You don't have any metadata"
                }
              </MenuButton>
              <MenuList maxHeight="300px" overflowY="scroll"> 
                {
                  metadatas.map((metadata, index) => (
                    <MenuItem onClick={() => { handleChangeActiveMetadata(index) }} >{metadata.id} - {metadata.contentName}</MenuItem>
                  ))
                }
              </MenuList>
            </Menu>
          <Text> or </Text>
          <Button variant="primary" onClick={() => { setShowCreationPage(true) }}>
            Create
          </Button>
        </HStack>
      )
    }

    {
      // Connected and has at least one metadata
      ! isLoading && active && showCreationPage && (
        <Container py={{ base: '4', md: '8' }}>
          <Flex align="center" gap="1" _hover={{ cursor: "pointer" }} onClick={handleResetCreation} pb="3">
            <ArrowBackIcon boxSize="16px" color="blue.500" /> 
            <Text fontWeight='semibold' color="blue.500" fontSize="sm">Back to your metadata</Text>
          </Flex>
          
          {
            currentStep === 0 && (
              <Stack spacing="5">
                <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between">
                  <Box>
                    <Text fontSize="lg" fontWeight="medium">
                      Metadata   
                    </Text>
                    
                    <Text color="muted" fontSize="sm">
                      Set your collection informations
                    </Text>
                  </Box>
                </Stack>
                <Divider />
                <Stack spacing="5" divider={<StackDivider />}>
                  <FormControl id="name">
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      spacing={{ base: '1.5', md: '8' }}
                      justify="space-between"
                    >
                      <FormLabel variant="inline">
                        Name
                        {' '} <InfoPopover header="Name" body="Name of the collection. This name will appear in the NFT metadata."/>
                      </FormLabel>
                      
                      
                      <Input maxW={{ md: '3xl' }} placeholder="Digital diamonds" value={collectionName} 
                        onChange={(e) => {setCollectionName(e.target.value)}} />
                    </Stack>
                  </FormControl>
                  <FormControl id="description">
                    <Stack
                      direction={{ base: 'column', md: 'row' }}
                      spacing={{ base: '1.5', md: '8' }}
                      justify="space-between"
                    >
                      <FormLabel variant="inline">
                        Description
                        {' '} <InfoPopover header="Description" body="Description of the collection. This will appear in the metadata of any NFT"/>
                      </FormLabel>
                      <Textarea maxW={{ md: '3xl' }} rows={5} placeholder="This collection of 10 diamonds represents..." 
                        minHeight="40px" value={collectionDescription} onChange={(e) => {setCollectionDescription(e.target.value)}}/>
                    </Stack>
                  </FormControl>

                  <Flex direction="row-reverse">
                    <Button variant="primary" onClick={() => {setStep(1)}}>Next</Button>
                  </Flex>
                </Stack>
              </Stack>
            )
          }
          
          {
            currentStep === 1 && (
              <Stack spacing="5">
                <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between">
                  <Box>
                    <Text fontSize="lg" fontWeight="medium">
                      Custom metadata
                      {' '} <InfoPopover header="Custom metadata" body="Custom attributes that will be assigned to any NFT in the collection"/>
                    </Text>
                    <Text color="muted" fontSize="sm">
                      Set custom fields for your collection
                    </Text>
                  </Box>
                </Stack>
                <Divider />
                {
                  customFields.map((item) => (
                    <CustomField key={item.id} field={item.field} id={item.id} value={item.value} updateField={handleUpdateCustomFieldField} 
                      updateValue={handleUpdateCustomFieldValue} delete={handleDeleteCustomField} />
                  ))
                }
                <Flex direction="row-reverse">
                  <Button variant="primary" onClick={() => {setStep(2)}}>Next</Button>
                  <Button variant="primary" onClick={handleAddCustomField} marginRight="5" >Add</Button>
                </Flex>
              </Stack>
            )
        }

        {
          currentStep === 2 && (
            <Stack spacing="5">
              <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between">
                <Box>
                  <Text fontSize="lg" fontWeight="medium">
                    Documents
                    {' '} <InfoPopover header="Documents" body="Documents that will be associated to your collection."/>
                  </Text>
                  <Text color="muted" fontSize="sm">
                    Upload your documents
                  </Text>
                </Box>
              </Stack>
              <Divider />
              
              <Dropzone width="full" updateFiles={handleDocumentsDrop} initialText="PDF, Word or Excel for up to 1GB"  accepted=".pdf,.docx,.xls,.xlsx,.odt" />

              <Flex direction="row-reverse">
                <Button variant="primary" onClick={() => {setStep(3)}}>Next</Button>
                <Button variant="primary" onClick={handleAddCustomField} marginRight="5" >Add</Button>
              </Flex>
            </Stack>
          )
        }

        {
          currentStep === 3 && (
            <Stack spacing="5">
              <Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between">
                <Box>
                  <Text fontSize="lg" fontWeight="medium">
                    Attributes
                    {' '} <InfoPopover header="Attributes" body="Attributes are properties of NFT that can be unique. You can set them after the creation process."/>
                  </Text>
                  <Text color="muted" fontSize="sm">
                    Set unique attributes for your NFT
                  </Text>
                </Box>
              </Stack>
              <Divider />
              {
                customAttributes.map((item) => (
                  <CustomAttribute key={item.id} name={item.name} id={item.id} updateName={handleUpdateAttribute} 
                    delete={handleDeleteAttribute} />
                ))
              }
              <Flex direction="row-reverse">
                <Button variant="primary" onClick={handleCreateMetadata}>Save</Button>
                <Button variant="primary" onClick={handleAddAttribute} marginRight="5" >Add</Button>
              </Flex>
            </Stack>
          )
        }

        <Box marginTop="5">
          <Container
            py={{
              base: '4',
              md: '8',
            }}
          >
            <Stack
              spacing="0"
              direction={{
                base: 'column',
                md: 'row',
              }}
            >
              {steps.map((step, id) => (
                <Step
                  key={id}
                  cursor="pointer"
                  onClick={() => setStep(id)}
                  title={step.title}
                  description={step.description}
                  isActive={currentStep === id}
                  isCompleted={currentStep > id}
                  isFirstStep={id === 0}
                  isLastStep={steps.length === id + 1}
                />
              ))}
            </Stack>
          </Container>
        </Box>
      </Container>
      )
    }

    {
      ! showCreationPage && (
        <Stack marginTop="5" spacing="5">
          <Stack
            spacing="4"
            direction={{
              base: 'column',
              sm: 'row',
            }}
            justify="space-between"
          >
            <Box>
              <Text fontSize="lg" fontWeight="medium">
                Add media files
                {' '} <InfoPopover header="Media files" body="Add media files (images, videos, gifs, ...) to your metadata."/>
              </Text>
              <Text color="muted" fontSize="sm">
                Here you can add media files to your metadata. Supported files are PNG, JPG, MP4 and GIF.
              </Text>
            </Box>
          </Stack>
          <Divider />
          <Stack spacing="5">
            <FormControl id="media">
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: '1.5', md: '8' }}
                justify="space-between"
              >
                <FormLabel variant="inline">
                  Media
                  {' '} <InfoPopover header="Media" body="Image, video or GIFs file of the collection. Must be numbered starting from 0 (i.e. 0.png, 1.png, 2.png and so on) and it's higly reccomended to use squared formats like 1080x1080."/>
                </FormLabel>
                <Stack
                  spacing={{ base: '3', md: '5' }}
                  direction={{ base: 'column', sm: 'row' }}
                  width="full"
                  maxW={{ md: '3xl' }}
                >
                  <Dropzone width="full" updateFiles={handleFilesDrop} />
                </Stack>
              </Stack>
            </FormControl>
            <Flex direction="row-reverse">
              <Button variant="primary" onClick={handleMediaUpload}>Upload</Button>
            </Flex>
          </Stack>

          <Stack
            spacing="4"
            direction={{
              base: 'column',
              sm: 'row',
            }}
            justify="space-between"
          >
            <Box>
              <Text fontSize="lg" fontWeight="medium">
                Your media
                {' '} <InfoPopover header="Your media files." body="These are the elements of your collection."/>
              </Text>
              <Text color="muted" fontSize="sm">
                Here you can set and update attributes for your NFTs.
              </Text>
            </Box>
          </Stack>
          <Divider />
          
          <MediaGrid>
            {
              currentMedia.map((mediaUrl, i) => (
                <MediaCard url={mediaUrl} mediaId={i} metadataId={activeMetadata.id} attributes={activeMetadata.attributes[i]} onUpdate={() => { handleUpdateMetadata(false) }} />
              ))
            }
          </MediaGrid>

        </Stack>
      )
    }

    </Container>
    </>
  )
}

export default Metadata;